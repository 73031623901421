<div class="sideMenu  overflow-auto">
    <div class="flex pt-4 align-items-center flex-column">
        <div class="primary" *ngFor="let menuItem of items" 
          (click)="openAutoTheFirstChildCategory(menuItem)"(mouseover)="updateActiveItem(menuItem)" >
            <a>
                <div class="selectItem text-center" [ngClass]="{'selectButtonType':menuItem==activeMenuItem}" 
                 (mouseover)="onSideMenuClick($event)" >
                    <img height="25" class="img" [ngClass]="{'imgWhite':menuItem==activeMenuItem}" pTooltip="{{menuItem.tooltip}}"
                        [src]="'assets/images/icons/'+menuItem.icon">
                </div>
                <div class="font-bold text-center pb-4 pt-1">{{menuItem.label}}
                </div>
            </a>
        </div>
    </div>
</div>
