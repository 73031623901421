import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReminderComponent } from '../components/reminder/reminder.component';
import { defaultPopupData, DynamicPopupService } from 'shared/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService {

  constructor(private httpClient: HttpClient,
    private dynamicPopupService: DynamicPopupService) { }

  validateCompanyCode(request: { companyCode: string }): Observable<any> {
    return this.httpClient.post<any>(`/companies/ValidateCompanyCode/`, request);
  }
  getCompanyLogo(): Observable<any> {
    return this.httpClient.get(`/companies/getCompanyLogo`);
  }
  addCompany(companyDetails: any): Observable<any> {
    return this.httpClient.post('/companies/addCompany', { Entity: companyDetails });
  }
  getListsToCompanySettings(): Observable<any> {
    return this.httpClient.get('/companies/getListsToCompanySettings');
  }
  getCompanyDetails(): Observable<any> {
    return this.httpClient.get<any>(`/companies/getCompanyWithSettings`);
  }
  saveCompanyDetails(companyDetails: any): Observable<any> {
    return this.httpClient.put('/companies/saveCompanyDetails', { Entity: companyDetails })
  }


  openReminder(reminder: any) {
    if (reminder.body !== "" && reminder.body !== null) {
      let data = {
        popupData: {
          ...defaultPopupData,
          header: "הודעה",
          style: { "width": "30rem" },
          class: "reminder-style",
          modal: true,
          closeOnSave: true,
          showCancelButton: false,
          showIcon: false
        },
        component: ReminderComponent,
        componentData: {
          body: reminder.body
        }

      };
      this.dynamicPopupService.openDynamicPopupClicked.emit(data);
    }
  }
}
