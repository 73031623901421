import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { DynamicPipe } from '../pipes/dynamic.pipe';
import { GridEnumTranslatePipe } from '../pipes/grid-enum-translate.pipe';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'view-object-details',
    templateUrl: './view-object-details.component.html',
    styleUrls: ['./view-object-details.component.scss']
})
export class ViewObjectDetailsComponent implements OnInit {

    private _object: any = {};
    @Input() set object(value: any) {
        if (value) {
            if (value.length) value = value[0];
            this._object = value;
            this.rowActions = this.generateRowActions(this.contextMenu);
        }
    }
    get object(): any {
        return this._object;
    }

    private _contextMenu: CallableFunction;
    @Input() set contextMenu(callback) {
        if (callback) {
            this._contextMenu = callback;
            this.rowActions = this.generateRowActions(callback);
        }
    }
    get contextMenu(): CallableFunction {
        return this._contextMenu;
    }

    @Input() displayKeys: any[] = [];
    @Input() moreDetails: boolean = false;

    @Output() actionSelected: EventEmitter<number> = new EventEmitter();

    enumPipe: GridEnumTranslatePipe;
    dynamicPipe: DynamicPipe;
    rowActions = [];

    constructor(
        private translateService: TranslateService,
        private injector: Injector
    ) {
        this.enumPipe = new GridEnumTranslatePipe(translateService, injector);
        this.dynamicPipe = new DynamicPipe(this.injector);
    }

    ngOnInit(): void {
    }

    getObjectProps(): any[] {
        if (this.moreDetails)
            return this.displayKeys.filter(prop => prop.value?.toString().length);
        //displayKeys not include the fields that declare in gridOptions.rowDetailsHiddenFields
        return this.displayKeys.filter(prop => this.object[prop.name]?.toString().length)
            .map(prop => ([prop.name, this.object[prop.name].length > 27 ? this.object[prop.name].substr(0, 27) : this.object[prop.name]])) ?? [];
    }

    getPropTitle(prop: any) {
        if (this.moreDetails)
            return prop.title;

        return this.displayKeys.find(k => k.name == prop[0])?.title ?? prop[0];
    }

    parseValue(prop: any): string {
        if (this.moreDetails)
            return prop.value;

        let srcSettings = this.displayKeys.find(k => k.name == prop[0]);

        // If column has value getter - use column value getter:
        if (srcSettings.src.valueGetter) return srcSettings.src.valueGetter({ data: this.object });

        // Parse different column types:
        switch (srcSettings.src.cellRenderer) {

            // Parse enums:
            case "customEnumTranslateColumn":
                return this.parseEnumValue(srcSettings.src, prop);

            // Parse pipes:
            case "customPipeColumn":
                return this.parsePipeColumnValue(srcSettings.src, prop);

            // Default - return original value:
            default:
                return prop[1].length > 25 ? prop[1].slice(0, 25) + "..." : prop[1];
        }
    }

    private convertActionToButtonExcludingSeparators(result, action) {
        return action != 'separator' ? [...result ?? [], { ...action, icon: this.getActionIcon(action.icon) }] : result
    }

    // Map actions to buttons, excluding separator items:
    private generateRowActions(callback: CallableFunction) {
        return callback?.({ node: { data: this.object } })
            ?.reduce((result, act) => this.convertActionToButtonExcludingSeparators(result, act), []) ?? [];
    }

    private getActionIcon(icon: string) {
        return icon?.replace("<i class=\"", "").replace("\"></i>", "");
    }

    private getColumnPipeParams(src: any): any[] {
        return src.cellRendererParams.cellParams.pipe.generateParamsByRowValue?.(this.object) ?? src.cellRendererParams.cellParams.pipe.params;
    }
    private getColumnCustomParams(src: any): any {
        return src.cellRendererParams.cellParams;
    }

    private getColumnPipeType(src: any): any {
        return src.cellRendererParams.cellParams.pipe.type;
    }

    private parsePipeColumnValue(src: any, prop: any): string {
        let type = this.getColumnPipeType(src);
        if (type)
            return this.dynamicPipe.transform(prop[1], type, this.object, this.getColumnPipeParams(src)) as string;
        else {
            let params = this.getColumnCustomParams(src);
            if (params) {
                return (this.object[params.prefix.field] ?? '') + params.prefix?.sep + prop[1] + (this.object[params.suffix.field] ? (params.suffix.sep ?? '') + this.object[params.suffix.field] : '')
            }
            else return prop[1];
        }
    }

    private parseEnumValue(src: any, prop: any): string {
        return this.enumPipe.transform(prop[1], src.customColumnParams.enumType, src.customColumnParams.enumName);
    }

}
