export enum Localization {
    He = 'he',
    En = 'en'
}
export enum Role {
    //CompanyUser = 2, is not used!!
    CompanyManager = 3,
    KesherUser = 5,
    Project = 8,
    Mobile = 10,
    Technician = 12
}

export enum VerificationType {
    Mail = 1,
    Phone,
}

export enum DetachObligationOptions {
    Cancel = 1,
    Connect = 2
}

export enum ExportType {
    Excel = 1,
    PDF = 2
}
export enum LoginStatus {
    Succeeded = 0,
    InvalidLogin,
    BlockedUser,
    RequiredVerification,
    VerificationCodeSent,
    InvalidVerificationCode,
    GeneralError,
    UserMailExists,
    UserPhoneExists,
    UserNotExists,
    SucceededButNoCompany
}


export enum SortDirection {
    Asc = 1,
    Desc = 2
}
export enum NavigateMode {
    Previous = 1,
    Next,
    First,
    Last
}

export enum SelectionMode {
    None = '',
    Multiple = 'multiple',
    Single = 'single'
}
export enum EntityState {
    NotChanged = 0,
    New = 4,
    Deleted = 8,
    Modified = 16
}


export enum MessageTypes {
    warn,
    error,
    success,
    info
}
export enum EditableMode {
    EditForNew,
    Edit,
    Disable
}
export enum FieldTypes {
    ColorPicker,
    LookupComboBox,
    EnumComboBox,
    DisabledComboBox,
    ComboBoxYN,
    Spinner,
    ViewFiled,
    DisabledFiled,
    DisabledSpinnerFiled,
    DisabledSearch,
    InputWithValidations,
    Search,
    Date,
    HebrewDate,
    ShortDate,
    DisabledDate,
    Password,
    Number,
    Amount,
    Tooltip,
    Tool,
    Template
}
export enum TransactionSumType {
    NIS = 1,
    Dollar = 2,
    Pound = 16,
    Euro = 19,
    WaitingShekelBit = 5
}
export enum CurrencyType {
    ILS = 1,
    USD = 2,
    GBP = 16,
    EUR = 19,
}
export enum CurrencyTypeSymbol {
    ILS = "₪",
    USD = "$",
    GBP = "£",
    EUR = "€",
}
export enum ChargeoptionType {
    All = 0,
    Credit = 1,
    Bank = 2,
    Cash = 3,
    Check = 4,
    Undefined = 5,
    Bit = 6,
    KesherWallet = 7,
    BankTransfer = 8,
    Wallet = 9,
    TransferByClick = 10
}
export enum ResolutionType {
    Desktop,
    Mobile
}

export enum TableType {
    Clients = 1,
    Obligations = 2,
    ChargeOptions = 3,
    Transactions = 4,
    Payment = 5,
    Projects = 7,
    Terminals = 8,
    Comapny = 9,
    TerminalToCompany = 10,
    CompanyTransferDetails = 11
}


export enum FamilyClosenessType {
    Son = 1,
    Daughter = 2
}

export enum PrivateStatus {
    Married = 1,
    Bachelor = 2,
    divorcee = 3
}

export enum ResponseStatus {
    Succeeded,
    NotSucceeded,
    Unauthorized
}
export enum TokenError {
    Invalid_grant = "invalid_grant",
    Missing_tfa_code = "missing_tfa_code",
    Missing_tfa_provider = "missing_tfa_provider",
    Invalid_tfa_provider = "invalid_tfa_provider",
    Invalid_tfa_code = "invalid_tfa_code",
    Invalid_captcha = "invalid_captcha",
    Invalid_secret_code = "invalid_secret_code",
    Captcha_timeout = "captcha_timeout",
    Verify_captcha_falied = "verify_captcha_falied",
    Unexpection_error = "unexpection_error"
}



export enum ForgotPasswordResultStatus {
    Success = 0,
    ResetEmailSent = 1,
    InvalidUserName,
    InvalidPhoneNumber
}


export enum ResetPasswordResultStatus {
    Succeeded = 1,
    InvalidUserId,
    PasswordNotValid,
    InvalidToken,
    UnknownError
}


export enum DisplayMode {
    HideBreadcrumbs = 1,
    HideActiveLink = 2,
    HideAll = 3
}
export enum ComponentType {
    breadcrumbs = 1,
    activePageLink = 2
}
export enum InputMode {
    Editing = 1,
    Reading = 2
}


export enum RangeDates {
    CurrentMonth = 1,
    PrevMonth,
    NextMonth,
    LastThirtyDays,
    Quarterly,
    YearAgo,
    CurrentDay,
    NextDay,
    PrevDay
}

export enum TransactionSearchOptions {
    ByTelOrMail = 1,
    BySum,

}
export enum TryAgainOptions {
    ExistingCredit = 1,
    NewCredit,
}
export enum CardUsingOptions {
    CurrentTran = 1,
    AllObligationsTrans
}
export enum TryAgainResponseStatus {
    Succeeded = 1,
    NotSucceeded,
    SucceededWithoutConfirmationNumber
}
export enum Issuers {
    Isracart = 1,
    Visa = 2,
    Dyners = 3,
    American = 4,
    // JCB = 5,
    Leumi = 6,

    Hide = 11,
    Loading = 10,
    Kesher = 9,
    Mastercard = 7,
}
export enum PaymentType {
    CreditCard = 1,
    BankDirectDebit,
    Single,
    Recurring,
}

export enum FormMode {
    View = 1,
    New = 2,
    Edit = 3,
    Card = 4
}
export enum LoginMode {
    Login = 1,
    New = 2,
    Forgot = 3,
    Verify = 4,
    Change = 5
}
export enum VerificationStatus {
    BlockedUser = 0,
    VerificationCodeSent,
    GeneralError,
    Verified
}
export enum CustomerFilterTabStatus {
    All = 0,
    Active = 1,
    NotActive = 2,
    Favorites = 3
}
export enum CustomerStatus {
    Active = 1,
    NotActive = 2,
}
export enum UserStatus {
    Active = 1,
    NotActive = 2,
}
export enum TeamStatus {
    All = 0,
    Active = 1,
    NotActive = 2,
}
export enum CustomerStatusIcon {
    All = "check-circle",
    Active = "check",
    NotActive = "times",
    Favorites = "star"
}

export enum MobileStatus {
    All = 0,
    Active = 1,
    NotActive = 2,
    MobileDevice = 3,
    VirtualDevice = 4
}
export enum MobileMode {
    Invalid = 0,
    OnHold = 1,
    Valid = 2
}
export enum TransactionStatus {
    Passed = 0,
    NotPassed = 1,
}
export enum DetailedTransactionStatus {
    WaitToSend = 1,
    Deleted = 2,
    Send = 3,
    Success = 4,
    WrongDetails = 5,
    Fail = 6,
    Cancelled = 7,
    WaitToClearing = 8,
    Returned = 9,
    InCheck = 10,
    JustReport = 11,
    TranAgain = 12,
    RemovedTran = 14,
    WrongDetailsInHok = 15,
    NoClearingTran = 16,
    ClearingByOutsideCompany = 18,
    WrongClearingByOutsideCompany = 19,
    WaitingToBitRes = 21,
}

export enum NotPassedTransactionStatus {
    OneTimeTransaction = 1,
    HokTransaction = 2,
    RemovedTransaction = 4
}
export enum PaymentPagesStatus {
    Active = 0,
    Archive = 1
}
export enum ProjectStatus {
    Active = 0,
    Archive = 1
}
export enum CategoryStatus {
    Active = 0,
    NotActive = 1
}
export enum SingleCompanyStatus {
    Active = 1,
    NotActive = 2
}
export enum CompanyStatus {
    All = 0,
    Active = 1,
    NotActive = 2
}
export enum ObligationViewStatus {
    All = 0,
    Active = 1,
    Canceled = 2,
    Finished = 3,
    WrongToTreat = 4,
    New = 6
}
export enum DetailedObligationStatus {
    Active = 1,
    Paused = 2,
    Cancelled = 3,
    NoBankAuth = 4,
    WaitingBankAuth = 5,
    CancelledBankAuth = 6,
    CancelledChargeOption = 7,
    Finished = 8,
    NotSuccessInitEmvHok = 9
}
export enum ObligationStatus {
    All = 0,
    Active = 1,
    Canceled = 2,
    Finished = 3,
    Future = 4,
    NotInitialized = 9,
    DetailedFinished = 8,
}
export enum ObligationWrongToTreatStatus {
    WrongToTreat = 1,// obligation that have notPassed >0 
    WrongTreated = 2,//obligation that have NotPassed=0 and TreatedDifferently>0
    MismatchedAmount = 3 //obligation that have NumPayments<>'��� �����' and FinalSum<>TotalSum and TotalSum<> 0  
}
export enum BooleanEnum {
    IsNot = 0,
    Is = 1,
}
export enum IncludsVat {
    Before = 0,
    After = 1,
}
export enum CustomerCardTabs {
    Obligations = 0,
    DigitalForms = 1,
    Calls = 2,
    Documents = 3,
}


export enum Validators {
    OnlyNumbers = 1,
    OnlyLetters = 2,
    PhoneNumber = 3,
    ValidPass = 4,
    pattern
}

export enum Patterns {
    "^[0-9]*$" = Validators.OnlyNumbers,
    "^([^0-9]*)$" = Validators.OnlyLetters,
    "^[0-9\+]{1,}[0-9\-]{7,10}$" = Validators.PhoneNumber,
    "^((?=.*[0-9])(?=.*[a-zA-Z]).{8,40})$" = Validators.ValidPass
}

export enum LocationTypes {
    MobileDevice = 0,
    FixedDevice = 1,
    VirtualDevice = 2
}

export enum CreditType {
    OneTimeBilling = 1,
    IsraCredit_Amex_Credit_VisaIsBetter30_DinersAreBetter30 = 2,
    ImmediateCharge = 3,
    ClubCredit = 4,
    SuperCredit = 5,
    CreditOrCreditInFixedPayments = 6,
    MultiplePaymentsTransactions = 8,
    ClubMultiplePaymentsTransactions = 9,
    Hok = 10,
    BankHok = 11
}

export enum CreditStatus {
    Right = 0,
    Blocked = 1,
    Stolen = 2,
    PhoneCreditCompany = 3,
    Refusal = 4,
    Forged = 5,
    WrongCvvID = 6,
    CardNotRight = 33,
    DateExpire = 36,
    WrongPayment = 37,
    CardNotExistOrTwice = 61,
    WrongTransactionType = 62,
    WrongDealType = 63,
    WrongCreditType = 64,
    WrongCurrency = 65,
    NotAllowToClearing = 101,
    WrongUserNameShva = 250,
    PasswordNotStrong = 400,
    UserANdPasswordSame = 401,
    Min8 = 402,
    MissingReqiredField = 403,
    WrongUserPassword = 404,
    WrongCreditCompany = 405,
    WrongDate = 406,
    WrongTotal = 407,
    WrongAddDtaa = 408,
    AuthCode = 409,
    MissingExpire = 410,
    WrongTerminal = 411,
    WrongCode = 412,
    Error = 413,
    NoSupplier = 414,
    NoActiveTerminal = 415,
    NoActiveUser = 416,
    CarNum = 417,
    ErrorJ = 418,
    ErrorService = 419,
    WrongNumPayment = 420,
    WrongFirstPayment = 421,
    DoubleTran = 422,
    ErrorDeposit = 423,
    NoDeposit = 424,
    FirstPassword = 425,
    PasswordEqualToOldPassword = 426,
    RedirectNoRole = 427,
    UserNotAllow = 428,
    PasswordExpire = 429,
    NotLinkedToMerchant = 430,
    LockedUser = 431,
    NoServicePassword = 432,
    WorngMail = 433,
    WrongToken = 434,
    WrongName = 435,
    WrongDataStructure = 436,
    WrongProjectNum = 437,
    NoTerminalsForCompany = 438,
    TerminalIsNotOfCompany = 439,
    IncorrectAnswer = 440,
    UserNameMailExist = 441,
    LinkExpire = 442,
    UsedLink = 443,
    LinkNotExist = 444,
    WrongCompanyUser = 445,
    TerminalNotRegistered = 446,
    WrongBank = 447,
    WrongBranch = 448,
    WrongAccountNum = 449,
    WrongComment = 450,
    NoReference = 451,
    WaitPayment = 452,
    JustReport = 454,
    unLock = 455,
    wrongTotalReceipt = 456,
    FutureJ2 = 457,
    SuccessHok = 458,
    NoToken = 459,
    ExistBankAuth = 460,
    WrongPhone = 461,
    WrongAddress = 462,
    WrongReceiptFor = 463,
    WrongReceiptName = 464,
    WrongIdentity = 465,
    RavMutavNohaveANumber = 466,
    ProjectNoActive = 467,
    NoHok = 468,
    NoClient = 469,
    TranNotFound = 470,
    FailSaveNoClearingTran = 471,
    WrongCompanyId = 472,
    TerminalNotBelongsToCompany = 473,
    TooHighSum = 474,
    TooLowSum = 475,
    OnlyShekelAllowed = 476,
    OnlyDebitAllowed = 477,
    WrongService = 482,
    CompanyNotRegisteredForService = 483,
    ClientHasNoChargeOptions = 487,
    NoChargeOption = 488,
    CannotDeleteHokWithPayment = 489,
    WrongPasswordServiceKesher = 490,
    EzCountNotAllowCreateRecieptBefor2017 = 491,
    EzCountProjectNoHaveDocType = 492,
    EzCountTranNotHaveReceiptNameOrReceiptFor = 493,
    EzCountFailToCreateReceiept = 494,
    FutureTranSuccess = 496,
    WrongTerminalToCompany = 497,
    FailConnectShva = 498,
    SuccessHokWithTran = 499,
    SuccessHokWithPay = 500,
    HokNotAllowed = 501,
    NoCreditNumNoToken = 600,
    KesherNoPayment = 852,
    ExecuteJ5TranOnlyInEmvTerminal = 853,
    ErrorCreateTErminalInShva = 854,
    CreditNotAllowedFutureOrHok = 857,
    OztarHasfarimFileTooBig = 859,
    OztarHasfarimFileWrong = 860,
    OztarHasfarimMissingRequeridFields = 861,
    OztarHasfarimMissinSuccessUpload = 862,
    KesherKalProjectNotExsits = 863,
    FailCreateClient = 865,
    TranHasClient = 866,
    PerceptionCreditFrameworkSuccess = 851,
    PerceptionCreditFrameworkNotSupported = 887,
    UpayClient = 932,
    J5TranNotAllow = 713,
    UserNotRegisteredInMeshulam = 201,
    UpayTran = 888,
    UserMailExist = 936,
    UserPhoneExist = 937,
    NotAllowToClearingEmv = 671,
    NewClient = 933,
    MeshulamTerminalNumberEmpty = 217,
    UpayInvalidePhone = 48,
    TerminalNotConnectToCompanyThisTran = 938,
    CreditNumContainsStars = 939,
    UpayWrongTerminal = 940,
    NotAllowedUserAPI = 210,
    CannotCancelTran = 941,
    ApiKeyNotValid = 96,
    UpaySuccessCancelTran = 97,
    NumPaymentWrong = 942,
    UpaySuccessCheckCancelTran = 95,
    NoActiveCompany = 943,
    SuccessAction = 944,
    ProjectDoesntBelongToCompany = 945,
    FailCreateHokFile = 965,
    TooMuchProjectData = 946,
    WrongCreditNumEmv = 757,
    WorngKehilotUser = 962,
    KehilotUserHasNoValidCreditNum = 963,
    KehilotUserHasNotVeriviedPhone = 964,
    DebitTransactionNotFound = 966,
    ErrorInCreditTransaction = 949,
    SuccessBankHok = 967,
    TerminalNotAllowOverseasCard = 968,
    IAmNotRobotRequired = 969,
    TransSumHighThenTotalSumToOblig = 970,
    RSTTran = 971,
    TimeOutOnTran = 972,
    WrongIpForAdminRole = 973,
    WrongBankAuth = 974,
    WorngToCreateReceiptToTranNotPassed = 975,
    ManyUsersWithTheSameDetails = 976,
    CannotPromotPaymentTran = 977,
    TerminalNotAllowedCreditType = 978,
    TerminalNotAllowedFieldOption = 979,
    TerminalNotAllowedPayments = 980,
    RegisteringToUpaySuccess = 981,
    UpayClosedTran1 = 982,
    UpayClosedTran2 = 983,
    UpayClosedTran3 = 984,
    NoIssuerAuthorization = 708,
    TranCompanyIdNotFound = 985,
    BitTranWaiting = 193,
    BitTranFailed = 986,
    TerminalToCompanyNotHasSettings = 987,
    NoChooseChargeOption = 988,
    ObligNotFound = 989,
    TooManyRows = 990,
    OneOrMoreParamsInvalid = 260,
    NotAllowChangeObligStatus = 991,
    PartialApproval = 606,
    CompanyCantSendHokForms = 340
}
export enum TransactionType {
    DebitTransaction = 1,
    CreditTransaction = 2,
    PartialApproval = 22
}
export enum ControlType {
    Calander = 1,
    DropDown,
    InputNumber
}

export enum UpdateLevel {
    Overview = 1,
    Deep = 2
}

export enum FieldOption {
    Required = 1,
    Hidden = 2,
    Editable = 3,
    ReadOnly = 4
}
export enum PaymentPageFieldType {
    Text = 1,
    Number = 2,
    Mail = 3,
    Phone = 4,
    Id = 5,
    CountryPrefix = 6,
    Date = 7,
    CVV = 8,
    Currency = 9,
}
export enum PaymentPagePaymentField {
    Date = 1,
    Cvv = 2,
    Sum = 3,
    Currency = 4,
    For = 5
}
export enum PaymentPagePaymentTypes {
    Single = 1,
    Payments = 2,
    StandingOrder = 3,
    PaymentsByStandingOrder = 4,
    BankStandingOrder = 5,
    BitUpay = 6,
    BitGama = 7,
    Cash = 10,
    TransferWithClick = 11
}


export enum DocumentTypes {
    Invoice_receipt = 35,
    Receipt = 39,
    ReceiptForDonation = 40
}

export enum ProduceDocument {
    AlwaysProductDocument = 1,
    CreateConcentratedReceiptAfterObligEnd = 2,
    DoNotGenerateDocumentsAutomatically = 3
}
export enum RavKavTypes {
    HopOn = 1,
    Online = 2
}
export enum Immediacy {
    High = 0,
    Medium = 1,
    Low = 2
}
export enum ReportStatus {
    All = 0,
    ImmediateTreatment = 1,
    AwaitingTreatment = 2,
    Handled = 3,
    Removed = 4,
    Refusal = 5
}
export enum OperationType {
    Fix = 0,
    Upgrade = 1,
    NewInstallation = 2
}

export enum PaymentPageTypeSingleOrPaymentOptions {
    OnlySingle = 1,
    OnlyPayments = 2,
    SingleAndPayments = 3
}
export enum PaymentPageType {
    Standard = 1,
    Iframe = 2
}
export enum IframeType {
    Transaction = 1,
    CreateToken = 2
}
export enum Language {
    Hebrew = 1,
    English = 2
}

export enum PageActivity {
    Active = 1,
    HasNotStartedYet = 2,
    Ended = 3
}

export enum StatsStatus {
    MonthlyRevenue = 1,
    Returned = 2,
    NewObligations = 3,
    Single = 4,
    ExpectSum = 5,
    DigitalForms = 6,
    Tests = 7
}

export enum DigitalFormsField {
    Logo = 0,
    OtherForm = 13,
    Image = 14,
    ShowText = 15,
    Text = 16,
    Number = 17,
    Mail = 18,
    Textarea = 19,
    DigitalSign = 20,
    Submit = 24,
    Payment = 25,
    Email = 26,
    Tz = 30,
    VerifiedPhone = 31,
    Date = 32,
    Link = 33,
    Select = 34,
    Check = 35,
    FullName = 37,
    Radio = 38,
    Products = 39,
}

export enum FieldIcons {
    Check = "check-square",
    Date = "calendar",
    DigitalSign = "cloud",
    Image = "image",
    Link = "link",
    Mail = "envelope",
    Number = "sort-numeric-down",
    OtherForm = "external-link",
    Payment = "dollar",
    ShowText = "window-maximize",
    Select = "list",
    Text = "pencil",
    Textarea = "align-left",
    Tz = "id-card",
    VerifiedPhone = "phone",
    FullName = "user",
    Products = "th-large"
}
export enum ObligationsSummaryType {
    MonthlySummary = 2,
    NewObligation = 1,

}


export enum TextAlign {
    Left = 0,
    Right = 1,
    Center = 2,
    Justify = 3
}


export enum ItemAlignment {
    Right = 0,
    Center = 1,
    Left = 2
}

export enum DigitalFormsFieldType {
    Input = 0,
    Display = 1,
    Actions = 2
}

export enum PaymentPageCustomerFields {
    Name = 1,
    FirstName = 2,
    LastName = 3,
    Id = 4,
    Phone = 5,
    CellPhone = 6,
    CountryPrefix = 7,
    Mail = 8,
    Address = 9,
    NumHouse = 10,
    Floor = 11,
    Apartment = 12,
    Enterance = 13,
    City = 14,
    Comment = 15
}

export enum PaymentPageTableType {
    RedirectPage = 0,
    PaymentPage = 1,
    NotConnected = 2
}
export enum Service {
    WithoutService = 0,
    Wcf = 1,
    Redirect = 2,
    Site = 3,
    BackOffice = 4,
    Mail = 5,
    Upload = 6,
    Token = 7,
    Wordpress = 8,
    Timer = 9,
    Troma = 10,
    Android = 11,
    NotPassTran = 12,
    Clients = 13,
    MSVfile = 14,
    ServiceOffice = 15,
    DigitalForms = 16
}
export enum DigitalFormStatus {
    NotSubmitted = 0,
    SubmittedButNotSent = 1,
    SentToManager = 2,
    JustOpened = 3,
    NowOnSending = 4
}
export enum LinkageCurrency {
    "" = 0,
    False = 1,
    True = 2
}

export enum BankAuthStatus {
    NotExist = -1,
    All = 0,
    //ההרשאה נפתחה – ניתן לשלוח חיובים להרשאה הזאת        
    Opened = 1,
    //ההרשאה נדחתה-אין לשלוח חיובים להרשאה הזאת 
    // שמתקבלת דחיה מתקבל גפ סיבת בדחיה
    Rejected = 2,
    //הרשאה בוטלה על-ידי הלקוח
    Canceled = 3,
    //ההרשאה הועברה מבנק אחר
    TransferredFromAnotherBank = 5,
    //הסבת חשבון ביוזמת הבנק הודעה למוסד בהעברת חשבונות של לקוחות לסניפים אחרים בשל מיזוג סניפים-6
    AccountTransferredByTheBank = 6,
    //הפשרת ביטול הרשאה-13
    CancelingCancellationBankAuth = 13

}

export enum DigitalFormsExportType {
    SingleFile = 0,
    ZipFile = 1
}

export enum AfterTransactionPassedAction {
    Url = 1,
    TQPage = 2
}

export enum AfterTransactionFailedAction {
    Url = 1,
    None = 2
}
export enum TransactionStatusType {
    Passed = 0,
    NotPassed = 1
}

export enum ImageCropShape {
    Square = 0,
    Round = 1
}
export enum NoLimit {
    NoLimitPayment = 9999,
    NoLimitSum = -1
}
export enum CheckExistMode {
    Exist = 1,
    NotExist = 2
}
export enum PaymentPageStatus {
    PageNotFound = 1,
    MissingDetails,
    ErrorInClientOrChargeOptionDetails,
    ClientNotExistInCompany,
    YouAreNotAllowedToOpenThisPage,
    ProductNotFoundInThisPage,
    AllProductsDeletedFromPage,
    PaymentTypesNotExistInPage
}

export enum FileType {
    Img = 0,
    Docx = 1,
    PDF = 2,
    Excel = 3,
    UnSupported = 4,
    Video = 5
}

export enum ManageLablesMode {
    Select = 0,
    Add = 1,
    Edit = 2
}

export enum SpecialLabelTypes {
    LearningOperation = 0,
    Tests = 1
}

export enum LearningOptionTypes {
    ShasBavli = 0,
    ShasYerushalmi = 1,
    Mishna = 2,
    Chumash = 3,
    Musar = 4,
    Halacha = 5
}
export enum OccupationType {
    Other = 305,
    General = 286
}
export enum SendingOptions {
    Mail = 1,
    WhatsApp = 2,
    SMS = 3
}

export enum PageCategoryType {
    PaymentPage = 1,
    Form = 2
}

export enum StudyPlaceType {
    TalmudTorah = 1,
    YeshivaKetana = 2,
    YeshivaGedola = 3,
    Kollel = 4,
    Other = 5
}

export enum SummaryGroupOptions {
    Month = 0,
    Project = 1,
    PaymentPage = 2,
    Groups = 4,
    Source = 5,
    New = 6,
    ChargeOption = 7,
    Product = 8
}
export enum SummarySaleGroupOptions {
    Products = 0,
    Customers = 1,
}

export enum DynamicPopupMode {
    Success = 0,
    Error = 1,
    Warn = 2,
    Add = 3,
    Info = 4,
    Edit = 5,
    SendMail = 6,
    EditUser = 7,
    Verify = 8
}

export enum InfoPanelFormatOptions {
    Date = 0,
    Currency = 1,
    Payments = 2,
    Enum = 3
}

export enum TerminalStatus {
    WithPayments = 0,
    WithOutPayments = 1,
}

export enum AddProductsSource {
    PaymentPage = 0,
    DigitalForm = 1,
    General = 2
}

export enum ObligationEditNotAllowedReasons {
    Products = 0,
    Payments = 1,
    Bit = 2,
    NotForClearing = 3
}

export enum TerminalTypes {
    Credit = 1,
    Bank = 2
}
export enum DetailedTerminalTypes {
    Ragil = 0,
    Ravsapak = 1,
    Ravmutav = 2,
    NoClearing = 3,
    BroadcastMasav = 4,
    DontBroadcastMasav = 5,
    ExtranalClearingStripe = 6,
    Upay = 7,
    Meshulam = 8,
    BitGama = 10,
    Credit = 12
}
export enum KesherProductsCategory {
    Credit = 1,
    Bank = 2,
    Kehilot = 3,
    General = 4,
    Feezback = 5
}
export enum ServiceChargeTypeEnum {
    OneTime = 1,
    Monthly = 2,
    StartOfMonth = 3,
    ActionsCount = 4,
    Percentages = 5
}
export enum AdversitingStatusEnum {
    IsLooking = 1,
    IsInterested = 2,
    IsRelevant = 3,
}

export enum AdverstingKehilotOccupation {
    LabelManager = 1,
    General = 2
}
export enum ViewType {
    Image = 1,
    Text = 2
}
export enum SavingCompanyUserStatus {
    Succeeded = 0,
    GeneralError,
    ExistCompanyUserError,
}
export enum AddingUserMailType {
    NewKesherUser,
    NewCompanyUser,
    ExistCompanyUser
}
export enum PopupType {
    DropDown = 1,
    List = 2,
    Empty = 3,
    ConstValue = 4,
    ConstList = 5
}
export enum ServicePaymentType {
    Debt = 1,
    Credit = 2,
    Collection = 3,
    WalletCollection = 4,
    Pay = 5,
    ManualPay = 6,
    WalletTransfer = 7,
    SpecialWalletTransfer = 8,
    Cancells = 9

}
export enum CampaignPageViewType {
    viewCampiagn = 1,
    allScreen = 2,
    paymentpage = 3
}
export enum TransferType {
    Regular = 1,
    Special,
}

export enum DynamicFieldsType {
    Clients = 1,
    Obligations = 2,
    Products = 3
}
export enum DataFieldType {
    Text = 1,
    Date = 2,
    Dropdown = 3,
    Number = 6
}
export enum CreditFileStatus {
    NotUpdate = 1,
    Broadcast = 2,
    Canceled = 3
}
export enum WorngEzCountFileData {
    NotFound = 1,
    Double = 2,
    NotConnectService = 3
}
export enum ProductTemplateType {
    PaymentPage = 1,
    Management = 2
}
export enum CRMServices {
    gabayNet = 1,
    mastercrm = 2,
    AMS = 3,
    general = 4
}
export enum CampignType {
    Kesher = 1,
    Charidy = 2
}
//export enum ChargeOptionsActivity {
//   Active=1,
//   Delete=3
//}

export enum ExportReportDates {
    OnlyToday = 1,
    Monthly = 2,
    RightToDate = 3
}
export enum CampiagnLInkType {
    HarnessScreen = 1,
    PaymentPage = 2,
    CampaignPage = 3
}

export enum ServiceProductActionType {
    Edit = 1,
    Add = 2,
    EditOrAdd = 3,
    Cancel = 4,
    Other = 5
}
export enum KesherService {
    EZCount = 36,
    SMSPhone = 49,
    IndependentInstitutionCode = 4,
    DigitalForms = 43,
    OtherServices = 35,
}

export enum BroadcastFileStatus {
    NotDefind = 0,
    IsDownLoad = 1,
    IsHidden = 2,
}

export enum ReportType {
    Daily = 0,
    Monthly = 1,
    Admin = 2,
    RangeDates = 3,
    Deposit = 4,
    Yearly = 5,
    ClientYearly = 6,
    Receipts = 20,
    Client = 7
}

export enum GroupEditigField {
    Sum = 0,
    Day = 1,
    ProjectAndPaymentPage = 2,
    PausedTill = 3,
    CancelPausedTill = 4,
    NumberPayment = 5
}

export enum PackageType {
    Payments = 1,
    StandingOrder = 2,
    FreeSum = 3
}

export enum CompanyType {
    UNKNOWN = 0,
    Association = 1,
    LicensedBusiness = 2,
    CompanyLtd = 3,
    ExemptBusiness = 6,
    HLC = 7
}

export enum EZCountErrors {
    CRNExist = 103,
    CompanyMailExist = 112
}