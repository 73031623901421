import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppState, selecCompanyInCommonState } from '../../../app-state.index';
import { DefaultLayoutComponent } from '../default-layout/default-layout.component';
import { PermissionMenuItem } from '../menu/permission-menu-item';
import { PermissionType } from '../../permissions/permission-type.enum';
import { PermissionsService } from '../../permissions/permissions.service';
import { asyncFilter, LocalizationService } from 'projects/shared/src/public-api';
import { MenuService } from '../menu/menu.service';
import { TopbarService } from '../topbar/topbar.service';


@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnDestroy {

  constructor(public localizationService: LocalizationService, private _cdr: ChangeDetectorRef, public app: DefaultLayoutComponent,
    private store: Store<AppState>, private permissionsService: PermissionsService, public topbarService: TopbarService,
    protected route: ActivatedRoute, private menuService: MenuService, private router: Router) {
    this.companySubscription = this.store.select(selecCompanyInCommonState).subscribe((company) => {
      if (company) {
        if (this.companyCode !== company.companyCode) {
          this.companyCode = company.companyCode;
          this.numAssociation = company.numAssociation;
          this.initMenu();
        }
      }
      else
        this.companyCode = null;

      if (!this.items) {
        this.initMenu();
      }

      this.initMenuByPermissios();
    });
  }
  @Output() activeItem = new EventEmitter<any>();
  companyCode: string;
  numAssociation: string;
  fullMenuItems: PermissionMenuItem[];
  @Input() activeMenuItem: PermissionMenuItem;
  items: MenuItem[];
  companySubscription: Subscription;
  initMenuByPermissios() {
    this.getPermittedMenuItems(this.fullMenuItems).then(menuItems => {
      this.items = menuItems?.filter(item => item.items && item.items.length > 0);
      if (this.items && this.items.length > 0) {
        this.activeItem.emit(this.activeMenuItem);
      }

      this._cdr.markForCheck();
    });
  }
  updateActiveItem(menuItem) {
    this.activeMenuItem = menuItem;
    this.activeItem.emit(menuItem);
  }
  firstChildCategory: any;
  openAutoTheFirstChildCategory(menuItem: any) { 
    if (menuItem.items) {
      this.firstChildCategory = menuItem.items[0].routerLink[0]
      this.router.navigate([this.firstChildCategory]);
    }
    this.openCloseMenu(menuItem)
  }
  async getPermittedMenuItems(items: PermissionMenuItem[]): Promise<MenuItem[]> {
    let permittedItems = items.filter(item => !this.companyCode ? item.allowWithoutCompany : item);
    permittedItems = await asyncFilter(permittedItems,
      async (item) => !item.permissionType || await this.permissionsService.permissionExists(item.permissionType).toPromise());

    return await Promise.all(permittedItems.map(async (item) => {
      const menuItem = { label: item.label, routerLink: item.routerLink, icon: item.icon,tooltip:item.tooltip, items: null };
      if (item.items) {
        menuItem.items = await this.getPermittedMenuItems(item.items);
      }
      return menuItem;
    }));
  }


  initMenu() {
    this.fullMenuItems = this.menuService.initMenu(this.companyCode, this.numAssociation);
  }
  screenBlocked: boolean = false;


  blockScreen() {
    this.screenBlocked = true;
    this._cdr.markForCheck();
  }

  onSelectSubMenuItem(status) {
    this.unBlockScreen();
  }

  unBlockScreen() {
    this.screenBlocked = false;
    this._cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }
  openCloseMenu(event) {
    this.topbarService.openCloseMenu.emit();
    this.app.onMenuButtonClick(event);
  }
  onSideMenuClick(event){
    this.app.onSideMenuClick(event);
  }
}
