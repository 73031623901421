<form [formGroup]="accountDetails" class="from-layout grid p-fluid  pl-0" >
    <!-- BANK  -->
    <div class="form-group  md:col-4 col-6 p-field" *ngIf="banks?.length" [updateValidations]="isUpdateValidations">
        <span class=" p-float-label">
            <p-dropdown name="bank" #bank appendTo="body" [options]="banks"
                (onChange)="filterBranches();updateValidation()" [autoDisplayFirst]="false" [(ngModel)]="selectedBank"
                optionLabel="name" formControlName="bank" [filter]="true" appendTo="body" filterBy="value,name"
                [showClear]="showClear">
                <ng-template pTemplate="selectedItem">
                    <div class="" *ngIf="selectedBank">
                        <div>{{selectedBank.value}} - {{selectedBank.name}}</div>
                    </div>
                </ng-template>
                <ng-template let-bank pTemplate="item">
                    <div class="">
                        <div>{{bank.value}} - {{bank.name}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <label>{{'BANK_DIRECT_DEBIT.BANK'| translate}}</label>
        </span>
    </div>

    <!-- BRANCH  -->
    <div class="form-group  md:col-4 col-6 p-field" *ngIf="bankBranches?.length ||!selectedBranchId"
        [updateValidations]="isUpdateValidations">
        <span class=" p-float-label">
            <p-dropdown id="branch" [options]="bankBranches" [(ngModel)]="selectedBranch" optionLabel="name"
                [autoDisplayFirst]="false" formControlName="branch" [filter]="true" filterBy="value,name"
                appendTo="body" [showClear]="showClear" (onChange)="updateValidation()">
                <ng-template pTemplate="selectedItem">
                    <div class="" *ngIf="selectedBranch">
                        <div>{{selectedBranch.value}} - {{selectedBranch.name}}</div>
                    </div>
                </ng-template>
                <ng-template let-branch pTemplate="item">
                    <div class="">
                        <div>{{branch.value}} - {{branch.name}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <label>{{'BANK_DIRECT_DEBIT.BRANCH'| translate}}</label>
        </span>
    </div>

    <!--ACCOUNT_NUMBER-->
    <div class="form-group md:col-4 col-12 p-field" [updateValidations]="isUpdateValidations">
        <span class=" p-float-label">
            <input pInputText pKeyFilter="int" formControlName="account" name="accountNumber" id="accountNumber"
                (blur)="updateValidation()" />
            <label for="accountNumber"> {{'BANK_DIRECT_DEBIT.ACCOUNT_NUMBER' | translate}} </label>
        </span>
    </div>
</form>