<p-dialog header="{{ 'MENU.COMPANIES_LIST' | translate }}" [(visible)]="displayCompaines" [modal]="true"
    [style]="{'width': '90vw'}" dynamic="true" (onHide)="onBeforeDialogHide($event)">

    <div style="height: 75vh;margin-top: 0.5rem">
        <ag-grid-dynamic #grid [columns]="columns" [options]="options" *ngIf="displayCompaines"
            [anotherSearch]="anotherSearch" [getRowData]="loadData.bind(this)"
            [sumHeaderText]="'COMPANIES.COMPANIES'|translate" [(selectedData)]="selectedData"
            (rowDoubleClickedEvent)="rowDoubleClicked($event)" [(loadDataParameters)]="loadDataParameters"
            [subStatusMenu]="moreFilters">
        </ag-grid-dynamic>
    </div>
</p-dialog>
<ng-template #newCompany>
    <company-details [isNew]="true" [userInfo$]="userInfo$"></company-details>
</ng-template>

<ng-template #anotherSearch>
    <div style="width: 9rem;" *ngIf="allowedPremiumControls==true" pTooltip="מוצג רק למנהלי קשר" tooltipPosition="left">
        <free-search [onlyNumbers]="true" [gridReloadData]="grid" gridReloadTrigger="freeSearchTextChange"
            (freeSearchTextChange)="searchByCompanyCode($event)" searchText="מס לקוח"></free-search>
    </div>
</ng-template>
<ng-template #moreFilters>
    <div class="flex gap-1">
        <span *ngIf="allowedPremiumControls==true">
            <p-checkbox [binary]="true" id="payCompany" class="pl-2" [(ngModel)]="payCompany"
                (onChange)="setDataSource()"></p-checkbox>
            <label for="testCompany">חברות עם אמצעי תשלום</label>
        </span>
        <span *ngIf="allowedPremiumControls==true">
            <p-checkbox [binary]="true" id="testCompany" class="pl-2" [(ngModel)]="testCompany"
                (onChange)="setDataSource()"></p-checkbox>
            <label for="testCompany">חברות טסט</label>
        </span>
    </div>
</ng-template>