<div [formGroup]="companyDetailsForm" *ngIf="company||isNew" class="grid gap-2 detailsContainer">
  <div class="grid col-11">
    <!-- LOGO -->
    <div class="col-12 flex justify-content-between">
      <upload-logo class="logo  p-field" (result)="result($event)" [imgUrl]="imgPath"></upload-logo>
      <div *ngIf="!IsTheCompanyActive&&!isNew">
        <h4 style="background: pink; text-align: center;">החברה לא פעילה </h4>
        {{ 'COMPANY_SETTINGS.COMPANY_STATUS.THE_COMPANY_CEASED_OPERATIONS_ON_THE_DATE' | translate }}
        <span>{{this.company.cancelDate|date:'dd/MM/yyyy'}}</span>
      </div>
      <div class="flex flex-wrap gap-1 h-2rem">
        <!-- ACTIVE -->
        <ng-container *ngIf="changeCompanyStateText === ''; else elseBlock">
          <button *ngIf="isKesherManager&&!isNew" (click)="beforeChangeCompanyState()" pButton
            class="p-button p-button-icon-only p-button-outlined" icon="pi pi-trash"
            [pTooltip]="'USER.DISABLE' | translate" tooltipPosition="bottom">
          </button>
        </ng-container>
        <ng-template #elseBlock>
          <button pButton class="p-button-outlined" *ngIf="isKesherManager&&!isNew" (click)="beforeChangeCompanyState()"
            label=" {{'USER.ENABLE'|translate}}">
          </button>
        </ng-template>
        <!-- SAVE -->
        <button *ngIf="!isNew" (click)="save()" [pTooltip]="'GENERAL.SAVE' | translate" tooltipPosition="bottom" pButton
          class=" p-button-outlined" icon="pi pi-save" iconPos="left" label="שמור"></button>
        <!-- ADD -->
        <button *ngIf="isNew" (click)="add()" [pTooltip]="'GENERAL.SAVE' | translate" tooltipPosition="bottom" pButton
          class="  p-button-outlined " icon="pi pi-save" [disabled]="onSaving"></button>
      </div>
    </div>
    <!-- Name-->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input id="name" pInputText formControlName="name" class="w-full" SelectText />
        <label for="name">{{ 'COMPANY_SETTINGS.COMPANY_DETAILS.COMPANY_NAME' | translate }}</label>
      </span>
    </div>
    <!-- Code-->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field " *ngIf=" !(isNew&&!isKesherManager)">
      <span class="p-float-label">
        <input id="companyCode" pInputText formControlName="companyCode" class="w-full" />
        <label for="companyCode">{{ 'COMPANY_SETTINGS.COMPANY_DETAILS.COMPANY_CODE' | translate }}</label>
      </span>
    </div>
    <!-- Insert date -->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <p-calendar appendTo="body" id="insertDate" formControlName="insertDate" dateFormat="dd/mm/yy">
        </p-calendar>
        <label for="insertDate">{{ 'GENERAL.INSERT_DATE' | translate }}</label>
      </span>

    </div>
    <!-- NUM Association-->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input id="numAssociation" pInputText formControlName="numAssociation" class="w-full" />
        <label for="numAssociation">{{ 'COMPANY_SETTINGS.COMPANY_DETAILS.NUM_ASSOCIATION' | translate }}</label>
      </span>
    </div>
    <!-- Company Type -->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <p-dropdown id="companyType" optionValue="id" optionLabel="name" placeholder="{{'GENERAL.CHOOSE' |  translate}}"
          formControlName="companyType" [options]="companyTypesList" [autoDisplayFirst]="false">
        </p-dropdown>
        <label class="title" for="companyType">
          {{'COMPANY_SETTINGS.COMPANY_DETAILS.COMPANY_TYPE' |translate }}
        </label>
      </span>
    </div>
    <!-- Address-->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input id="address" pInputText formControlName="address" class="w-full" />
        <label for="address">{{ 'COMPANY_SETTINGS.COMPANY_DETAILS.ADDRESS' | translate }}</label>
      </span>
    </div>
    <!-- HOUSE_NUMBER-->
    <!-- <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input id="numHouse" pInputText formControlName="numHouse" class="w-full" />
        <label for="numHouse">{{ 'COMPANY_SETTINGS.COMPANY_DETAILS.HOUSE_NUMBER' | translate }}</label>
      </span>
    </div> -->
    <!--CITY_NAME-->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <!-- <p-autoComplete appendTo="body" name="cityName" formControlName="cityName" autocomplete="new-password"
          [suggestions]="cities" [dropdown]="true" (completeMethod)="allIsraeliCities($event)"
          dropdownMode="current"></p-autoComplete> -->
        <input id="cityName" pInputText formControlName="cityName" class="w-full" />
        <label for="cityName">{{ 'COMPANY_SETTINGS.COMPANY_DETAILS.CITY' | translate }}</label>
      </span>
    </div>
    <!-- COUNTRY -->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input id="country" pInputText formControlName="country" class="w-full" />
        <label for="country">{{ 'COMPANY_SETTINGS.COMPANY_DETAILS.COUNTRY' | translate }}</label>
      </span>
    </div>
    <!-- PHONE  -->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input class="phone1" id="phone1" pInputText name="phone1" autocomplete="new-sett" formControlName="phone1" />
        <label for="phone1"> {{ 'COMPANY_SETTINGS.COMPANY_DETAILS.PHONE1' | translate }} </label>
      </span>
    </div>
    <!------ ADDITIONAL PHONE ------>
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input class="phone" id="phone2" pInputText name="phone2" formControlName="phone2" />
        <label for="phone2"> {{ 'COMPANY_SETTINGS.COMPANY_DETAILS.PHONE2' | translate }} </label>
      </span>
    </div>
    <!--MAIL-->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <p-chips formControlName="mail" [addOnBlur]="true" separator=";"
          (onChipClick)=copyToClipboard($event)></p-chips>
        <label for="mail">{{'USER.MAIL' | translate}}</label>
      </span>
    </div>
    <!------ FAX ------>
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input id="fax" pInputText name="fax" formControlName="fax" />
        <label for="fax"> {{ 'COMPANY_SETTINGS.COMPANY_DETAILS.FAX' | translate }} </label>
      </span>
    </div>
    <!------ OCCUPATIONS ------>
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field "
      *ngIf="occupationsCompanyList && occupationsCompanyList.length>0">
      <span class="p-float-label">
        <p-dropdown id="occupation" optionValue="id" optionLabel="name" placeholder="{{'GENERAL.CHOOSE' |
            translate}}" formControlName="occupation" [options]="occupationsCompanyList" [autoDisplayFirst]="false">
        </p-dropdown>
        <label class="title" for="occupation">{{'COMPANY_SETTINGS.COMPANY_DETAILS.OCCUPATION' | translate }} </label>
      </span>
    </div>
    <!-- COMMENT-->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input id="comment" pInputText formControlName="comment" class="w-full" />
        <label for="comment">{{ 'COMPANY_SETTINGS.COMPANY_DETAILS.COMMENTS' | translate }}</label>
      </span>
    </div>
    <!------ PUBLIC_PHONE ------>
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <input class="phone" id="publicPhone" pInputText name="publicPhone" formControlName="publicPhone" />
        <label for="publicPhone"> {{ 'COMPANY_SETTINGS.COMPANY_DETAILS.PUBLIC_PHONE' | translate }} </label>
      </span>
    </div>
    <!--PUBLIC_MAIL-->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <!-- <input id="publicMail" type="email" pInputText name="publicMail" formControlName="publicMail" EmailExtenstion
          [control]="companyDetailsForm.controls.publicMail" /> -->
        <p-chips formControlName="publicMail" [addOnBlur]="true" separator=";"
          (onChipClick)=copyToClipboard($event)></p-chips>

        <label for="publicMail">{{'COMPANY_SETTINGS.COMPANY_DETAILS.PUBLIC_MAIL'| translate}}</label>
      </span>
    </div>
    <!-- company_info -->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <textarea [rows]="1" [cols]="30" pInputTextarea id="companyInfo" formControlName="companyInfo" pInputTextarea
          autocomplete="new-sett"></textarea>
        <label for="companyInfo">{{'COMPANY_SETTINGS.COMPANY_DETAILS.COMPANY_INFO'| translate}}</label>
      </span>
    </div>
    <!-- COMPANY_REMINDER -->
    <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
      <span class="p-float-label">
        <textarea [rows]="1" [cols]="30" pInputTextarea id="companyReminder" formControlName="companyReminder"
          pInputTextarea autocomplete="new-sett"></textarea>
        <label for="companyReminder">{{'COMPANY_SETTINGS.COMPANY_DETAILS.COMPANY_REMINDER'| translate}}</label>
      </span>
    </div>

    <!-- ONLY KESHER MANAGER -->
    <div class="col-12 flex">
      <!-- CRM_SERVICES -->
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
        <span class="p-float-label">
          <p-dropdown id="crmService" placeholder="{{'GENERAL.CHOOSE' |  translate}}" formControlName="crmService"
            [showClear]="true" [options]="crmServicesList" [autoDisplayFirst]="false" optionValue="label"
            (onChange)="updateValidation()">
            <ng-template let-item pTemplate="item">
              {{ item.label | enumTranslate: crmServices }}
            </ng-template>
            <ng-template let-item pTemplate="selectedItem">
              {{ item.label | enumTranslate: crmServices }}
            </ng-template>
          </p-dropdown>
          <label class="title" for="crmService">
            {{'COMPANY_SETTINGS.COMPANY_DETAILS.CRM_SERVICES' |translate }}
          </label>
        </span>
      </div>
      <!--callback url for CRM_SERVICES -->
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field"
        *ngIf="this.companyDetailsForm?.get('crmService').value==crmServices.general">
        <span class="p-float-label">
          <input id="crmServiceCallbackUrl" pInputText formControlName="crmServiceCallbackUrl" class="w-full" />
          <label for="crmServiceCallbackUrl">{{'COMPANY_SETTINGS.COMPANY_DETAILS.CRM_SERVICE_CALLBACK_URL' |translate
            }}</label>
        </span>
      </div>
    </div>
    <div class="col-12 flex" *ngIf="isKesherManager">
      <!-- ALLOW_PREMIUM_CONTROLS -->
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
        <span>
          <p-checkbox [binary]="true" id="enableUsingPremiumDigitalForms"
            formControlName="enableUsingPremiumDigitalForms" class="pl-2"></p-checkbox>
          <label for="enableUsingPremiumDigitalForms">{{'COMPANY_SETTINGS.COMPANY_DETAILS.USE_PREMIUM_CONTROLS'
            |translate}}</label>
        </span>
      </div>
      <!-- EZCOUNT_PRIMARY_COMPANY -->
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field  mt-1 pt-1">
        <span class="w-full">
          <p-checkbox [binary]="true" id="ezCountPrimaryCompany" formControlName="ezCountPrimaryCompany"
            class="pl-2"></p-checkbox>
          <label for="ezCountPrimaryCompany">{{'COMPANY_SETTINGS.COMPANY_DETAILS.EZCOUNT_PRIMARY_COMPANY'
            |translate}}</label>
        </span>
        <br/>
        <span class="w-full text-sm">יש צורך להגדיר שדה זה רק אם החפ שמור על כמה חברות</span>
      </div>
      <!-- SHOW DECODING TOKEN -->
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field">
        <span>
          <p-checkbox [binary]="true" id="ShowDecodingT" formControlName="showDecodingT" class="pl-2"></p-checkbox>
          <label for="showDecodingT">הצג פענוח טוקן</label>
        </span>
      </div>
      <!-- TEST COMPANY  -->
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field">
        <span>
          <p-checkbox [binary]="true" id="testCompany" formControlName="testCompany" class="pl-2"></p-checkbox>
          <label for="testCompany">חברת טסט</label>
        </span>
      </div>

    </div>
    <div class="col-12 flex" >

      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field" *ngIf="isKesherManager">
        <span>
          <p-checkbox [binary]="true" id="CanAddCompany" formControlName="canAddCompany" class="pl-2"></p-checkbox>
          <label for="canAddCompany">אפשר הקמת חברות בAPI </label>
        </span>
      </div>
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field" *ngIf="isKesherManager">
        <span>
          <p-checkbox [binary]="true" id="MoveNotPassedTranToEndOfOblig" formControlName="moveNotPassedTranToEndOfOblig"
            class="pl-2"></p-checkbox>
          <label
            for="moveNotPassedTranToEndOfOblig">{{'COMPANY_SETTINGS.COMPANY_DETAILS.MOVE_NOT_PASSED_OBLIG_TRAN_TO_END_OBLIGATION'
            |translate}}
          </label>
        </span>
      </div>
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field">
        <span>
          <p-checkbox [binary]="true" id="HasSection46" formControlName="hasSection46" class="pl-2"></p-checkbox>
          <label for="hasSection46">{{'COMPANY_SETTINGS.COMPANY_DETAILS.HAS_SECTION_46'
            |translate}}
          </label>
        </span>
      </div>
    
    </div>
    <p-divider class="col-12"></p-divider>
    <company-default-tremianl class="col-12 " *ngIf="!isNew" #defaultTerminals
      [editAble]="false"></company-default-tremianl>

    <!--ALLOW_CREDIT_COMPANY ACOUNT_DETAILS-->
    <div *ngIf="isKesherManager" class="col-12 grid">
      <p-divider class="col-12"></p-divider>
      <span class="col-12"><b>{{'COMPANY_SETTINGS.COMPANY_DETAILS.BANK_CREDIT_DETAILS'| translate}}</b></span>
      <div class="form-group lg:col-6 md:col-6 s:col-12 p-field ">
        <span>
          <p-checkbox [binary]="true" id="enableCredit" formControlName="enableCredit" class="pl-2"
            [(ngModel)]="enableCredit"></p-checkbox>
          <label for="enableCredit">{{'COMPANY_SETTINGS.COMPANY_DETAILS.ENABLE_CREDIT' |translate}}</label>
        </span>
      </div>
      <account-details class="col-9" [requiredIfOneFilled]="true" [accountDetails]="getAccountDetailsFormGroup()"
        [selectedBankId]="this.company?.companySettings.bank"
        [selectedBranchId]="this.company?.companySettings.branch"></account-details>
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
        <span class="p-float-label">
          <input id="tz" pInputText name="tz" formControlName="tz" />
          <label for="tz">{{'COMPANY_SETTINGS.COMPANY_DETAILS.TZ'| translate}}</label>
        </span>
      </div>
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
        <span class="p-float-label">
          <input id="nameAccountOwner" pInputText name="nameAccountOwner" formControlName="nameAccountOwner" />
          <label for="nameAccountOwner">{{'COMPANY_SETTINGS.COMPANY_DETAILS.NAME_ACCOUNT_OWNER'| translate}}</label>
        </span>
      </div>
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
        <span class="p-float-label">
          <input id="moreInfo" pInputText name="moreInfo" formControlName="moreInfo" />
          <label for="moreInfo">{{'COMPANY_SETTINGS.COMPANY_DETAILS.MORE_INFO'| translate}}</label>
        </span>
      </div>
      <div class="form-group lg:col-3 md:col-6 s:col-12 p-field ">
        <span class="p-float-label">
          <input id="creditGroup" pInputText name="creditGroup" formControlName="creditGroup" />
          <label for="creditGroup">{{'COMPANY_SETTINGS.COMPANY_DETAILS.CREDIT_GROUP'| translate}}</label>
        </span>
      </div>
    </div>
  </div>
</div>