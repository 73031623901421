<hr/>

<!-- Details -->
<div class="grid mr-6 mt-7">
    <div class="col-6" *ngFor="let prop of getObjectProps()">
        <div class="font-light text-gray-400"> {{ getPropTitle(prop) }}</div>
        <div class="font-bold text-gray-900"> {{ parseValue(prop) }}</div>
    </div>
</div>

<!-- Actions -->
<div class="grid mt-6">
    <div *ngFor="let action of rowActions; last as last" 
        [class]="last && rowActions.length % 2 ? 'col-12' : 'col-6'"
        class="p-1">
        <button 
            pButton 
            class="w-full"
            [label]="action.name" 
            [icon]="action.icon"
            (click)="action.action()">
        </button>
    </div>
</div>
