<div class="layout-topbar">
    <div class="layout-topbar-left justify-content-between"  [ngClass]="{'h-auto':isMobile }" >
        <div class="flex gap-1-2 justify-content-around pr-3">
            <div class="mobile-fast-payment-button">
                <a class="menuButton" (click)="openCloseMenu($event)" pRipple>
                    <i> <img style="height: 1.5rem;" src="assets/images/icons/menu.png"></i>
                </a>
            </div>
            <div>
                <a [ngClass]="{'mobile':isMobile ,'layout-topbar-logo':!isMobile}" [routerLink]="getLogoRouterLink()"
                    pTooltip="דף הבית" tooltipPosition="top">
                    <img *ngIf="!isMobile" id="app-logo " class="p-3 md:p-1" src="/assets/images/kesher_logo.png"
                        alt="ultima-layout" style="height: 3.5rem;">
                    <img *ngIf="isMobile" id="app-logo " src="/assets/images/kesher_logo.png"
                        style="height: 1.5rem;">

                </a>
            </div>
        </div>

        <div *ngIf="isMobile" class="flex gap-1 align-items-center pr-3">


            <div [pTooltip]="'TOP_MENU.CHANGE_COMPANY' | translate" tooltipPosition="top">
                <img *ngIf="(company$| async)?.imgPath " (click)="displayChangeCompany = true"
                    src="{{(company$| async)?.imgPath}}" class="rounded-circle h-2rem w-2rem cursor-pointer"
                    alt="avatar" />
            </div>
            <div *ngIf="!(company$| async)?.imgPath" (click)="displayChangeCompany = true"
                class="flex rounded-circle h-2rem w-2rem bg-primary text-center flex justify-content-center align-items-center cursor-pointer">
                {{logoFirstLetterCompanyName((company$| async)?.companyName)}}
            </div>




            <div class="flex">
                <a class="layout-topbar-mobile-button" (click)="item.toggle($event)" pRipple>
                    <i class="pi pi-ellipsis-v fs-large black-color"></i>
                </a>
                <p-overlayPanel #item>
                    <ng-template pTemplate>
                        <div class="flex flex-column gap-1/2">
                            <option class="pointer font-bold" (click)="openUserDetails()">הגדרות משתמש</option>
                            <option class="pointer font-bold" [routerLink]="getCompanyNameRouterLink()">הגדרות חברה</option>
                            <option class="pointer font-bold" (click)="openKesherProducts()">שירותים ומוצרים</option>
                            <option class="pointer font-bold" (click)="authService.logout()">התנתק</option>
                        </div>
                    </ng-template>
                </p-overlayPanel>
            </div>
        </div>


        <div *ngIf="(company$| async)&&!isMobile" class="flex gap-1">
            <div>
                <button pButton pRipple (click)="openFastPayment()" label="{{'TOP_MENU.FAST_PAYMENT'|translate}}"
                    class="p-button-outlined roundButton" icon="pi pi-credit-card"></button>
            </div>
            <div class="ml-2 ">
                <button pButton type="button" (click)="openKesherProducts()"
                    label="{{'TOP_MENU.KESHER_PRODUCTS'|translate}}" icon="pi pi-cog"
                    class="p-button-outlined roundButton"></button>
            </div>
        </div>
    </div>
    <div class="layout-topbar-right flex-grow-0 mr-auto company"
        [ngClass]="{'layout-topbar-mobile-active': defaultLayout.mobileTopbarActive}">
        <div class="layout-topbar-actions-left flex mb-auto mt-auto gap-1">


            <div [pTooltip]="'TOP_MENU.CHANGE_COMPANY' | translate" tooltipPosition="top">
                <img *ngIf="(company$| async)?.imgPath " (click)="displayChangeCompany = true"
                    src="{{(company$| async)?.imgPath}}" class="rounded-circle h-3rem w-3rem cursor-pointer"
                    alt="avatar" />

                <div *ngIf="!(company$| async)?.imgPath" (click)="displayChangeCompany = true"
                    class="rounded-circle h-3rem w-3rem bg-primary text-center flex justify-content-center align-items-center cursor-pointer">
                    {{logoFirstLetterCompanyName((company$| async)?.companyName)}}
                </div>
            </div>
            <div class="flex gap-1/2 pointer" [pTooltip]="'TOP_MENU.DEFINITIONS_AND_PAYMENTS' | translate"
                tooltipPosition="top" [routerLink]="getCompanyNameRouterLink()">
                <div class="display-block fw-900 hidden md:inline font-bold ">
                    {{(company$| async)?.companyCode}}<b> | </b>{{(company$|async)?.companyName}}
                </div>

                <i class="pi pi-cog pt-1 pl-3"></i>
            </div>
            <div>
                <div
                    class="userNameWarpper   flex flex-column justify-content-center gap-1/2  xl:p-3 lg:p-3 md:p-2 sm:p-2 p-2 lg:h-5rem md:h-4rem  sm:h-4rem">

                    <span [pTooltip]="'TOP_MENU.PERSONAL_DETAILS' | translate"
                        class="display-block floatingMenu userName  pointer" (click)="op.toggle($event)"> שלום
                        {{(userInfo$| async)?.firstName}}
                        {{(userInfo$| async)?.lastName}}
                    </span>

                    <p-overlayPanel #op>
                            <ng-template pTemplate>
                                <div class="flex flex-column gap-1/2">
                                <option class="pointer font-bold" (click)="openUserDetails()">עריכת פרטי משתמש</option>
                                <option class="pointer font-bold" (click)="authService.logout()">התנתק</option>
                            </div>
                            </ng-template>
                    </p-overlayPanel>


                    <span class="fs-small pl-3 userName" *ngIf="lastEntranceDate"> כניסה אחרונה: {{lastEntranceDate|
                        date:'HH:mm
                        dd/MM/yy'}}
                    </span>

                </div>
            </div>
        </div>
    </div>
</div>

<company [displayCompaines]="displayChangeCompany" (onClose)="onCloseCompanies()"></company>
<!------USER EDIT MODAL------>
<dynamic-popup [header]="'TOP_MENU.USER_DETAILS.TITLE' | translate" [mode]="popupModes.EditUser" [modal]="true"
    [visible]="displayUserEditModal" [style]="{width: '25vw'}" acceptLabel="GENERAL.OK" cancelLabel="GENERAL.CANCEL"
    (cancel)="onCloseUserEditModal($event)" (accept)="acceptUserDetails()">
    <user-details #content [userInfo]="userInfo" *ngIf="displayUserEditModal"></user-details>
</dynamic-popup>