import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "projects/shared/src/lib/shared.module";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { DefaultLayoutComponent } from "./components/default-layout/default-layout.component";
import { AppMenuComponent } from "./components/menu/menu.component";
import { CoreRoutingModule } from "./core-routing.module";
import { coreReducer } from "./state/state.index";
import { CompanyComponent } from './components/company/company.component';
import { PermissionsService } from "./permissions/permissions.service";
import { PermissionsGuard } from "./permissions/permissions.guard";
import { AppBreadcrumbComponent } from "./components/breadcrumb/app.breadcrumb.component";
import { AppTopBarComponent } from "./components/topbar/app.topbar.component";
import { AppMenuitemComponent } from "./components/menu/menu-item.component";
import { AppBreadcrumbService } from "./components/breadcrumb/app.breadcrumb.service";
import { MenuService } from "./components/menu/menu.service";
import { CompanyGuard } from "../kesher-shared/guards/company.guard";
import { VendorsModule } from 'vendors';
import { KesherSharedModule } from "../kesher-shared/kesher-shared.module";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { AllCompaniesComponent } from './components/all-companies/all-companies.component';
import { GridModule } from "projects/grid/src/lib/grid.module";
import { EnumTranslatePipe, SideBarService } from "shared/src/public-api";
import { ConcatUsModule } from "../concat-us/contact-us.module";

@NgModule({
  exports: [
  ],
  imports: [
    GridModule,
    //.forRootOrChild(),
    CommonModule, CoreRoutingModule, SharedModule.forRootOrChild(), VendorsModule.forRootOrChild(),
    MessageModule,
    MessagesModule,
    ConfirmDialogModule,
    StoreModule.forFeature('core', coreReducer),
    KesherSharedModule.forRootOrChild(),
    ConcatUsModule
  ],
  declarations: [
    AppMenuComponent,
    DefaultLayoutComponent,
    CompanyComponent,
    AppBreadcrumbComponent,
    AppTopBarComponent,
    AppMenuitemComponent,
    SideMenuComponent,
    AllCompaniesComponent
   ],

  providers: [EnumTranslatePipe, CompanyGuard, SideBarService, PermissionsService,
    PermissionsGuard, AppBreadcrumbService, MenuService]
})
export class CoreModule { }
